import { css } from 'linaria';
import { standardLightStyles } from '@alphaflow/style';

const root = css`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  position: relative;
  text-align: center;
  z-index: 1000;

  & > .__ring,
  & > .__spinner {
    border-radius: 50%;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  & > .__ring {
    border: 2px solid ${standardLightStyles.neutral300};
    z-index: 1;
  }

  & > .__spinner {
    z-index: 2;
    animation: spin 600ms linear infinite;
    border: 2px solid transparent;
    border-top-color: ${standardLightStyles.neutral700};
  }
`;

export default {
  root,
};
