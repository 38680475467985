import { css } from 'linaria';
import baseStyles from './base';
import standardLightStyles from './standardLight';

export default css`
  :global() {
    // Lato

    @font-face {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      src: url('./fonts/Lato_400_normal.woff2') format('woff2'),
        url('./fonts/Lato_400_normal.woff') format('woff'),
        url('./fonts/Lato_400_normal.ttf') format('truetype');
    }

    @font-face {
      font-family: 'Lato';
      font-style: italic;
      font-weight: 400;
      src: url('./fonts/Lato_400_italic.woff2') format('woff2'),
        url('./fonts/Lato_400_italic.woff') format('woff'),
        url('./fonts/Lato_400_italic.ttf') format('truetype');
    }

    @font-face {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      src: url('./fonts/Lato_700_normal.woff2') format('woff2'),
        url('./fonts/Lato_700_normal.woff') format('woff'),
        url('./fonts/Lato_700_normal.ttf') format('truetype');
    }

    @font-face {
      font-family: 'Lato';
      font-style: italic;
      font-weight: 700;
      src: url('./fonts/Lato_700_italic.woff2') format('woff2'),
        url('./fonts/Lato_700_italic.woff') format('woff'),
        url('./fonts/Lato_700_italic.ttf') format('truetype');
    }

    // Montserrat

    @font-face {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      src: url('./fonts/Montserrat_400_normal.woff2') format('woff2'),
        url('./fonts/Montserrat_400_normal.woff') format('woff'),
        url('./fonts/Montserrat_400_normal.ttf') format('truetype');
    }

    @font-face {
      font-family: 'Montserrat';
      font-style: italic;
      font-weight: 400;
      src: url('./fonts/Montserrat_400_italic.woff2') format('woff2'),
        url('./fonts/Montserrat_400_italic.woff') format('woff'),
        url('./fonts/Montserrat_400_italic.ttf') format('truetype');
    }

    @font-face {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      src: url('./fonts/Montserrat_600_normal.woff2') format('woff2'),
        url('./fonts/Montserrat_600_normal.woff') format('woff'),
        url('./fonts/Montserrat_600_normal.ttf') format('truetype');
    }

    @font-face {
      font-family: 'Montserrat';
      font-style: italic;
      font-weight: 600;
      src: url('./fonts/Montserrat_600_italic.woff2') format('woff2'),
        url('./fonts/Montserrat_600_italic.woff') format('woff'),
        url('./fonts/Montserrat_600_italic.ttf') format('truetype');
    }

    @font-face {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      src: url('./fonts/Montserrat_700_normal.woff2') format('woff2'),
        url('./fonts/Montserrat_700_normal.woff') format('woff'),
        url('./fonts/Montserrat_700_normal.ttf') format('truetype');
    }

    @font-face {
      font-family: 'Montserrat';
      font-style: italic;
      font-weight: 700;
      src: url('./fonts/Montserrat_700_italic.woff2') format('woff2'),
        url('./fonts/Montserrat_700_italic.woff') format('woff'),
        url('./fonts/Montserrat_700_italic.ttf') format('truetype');
    }

    // Reset

    *,
    *:before,
    *:after {
      box-sizing: inherit;
      box-sizing: inherit;
    }

    html {
      box-sizing: border-box;
    }

    input[type='text'],
    input[type='email'],
    input[type='search'],
    input[type='password'] {
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    // Page

    html,
    body {
      height: 100%;
    }

    body {
      margin: 0px;
      padding: 0px;
      overflow-x: hidden;
      min-width: 320px;
      font-family: ${baseStyles.bodyFontFamily};
      line-height: 1.4285em;
    }

    body {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    // Headers

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: ${baseStyles.headerFontFamily};
      line-height: 1.28571429em;
      margin: calc(2rem - 0.14285714em) 0em 1rem;
      font-weight: bold;
      padding: 0em;
    }

    h1 {
      min-height: 1rem;
      font-size: 2rem;
    }

    h2 {
      font-size: 1.71428571rem;
    }

    h3 {
      font-size: 1.28571429rem;
    }

    h4 {
      font-size: 1.07142857rem;
    }

    h5 {
      font-size: 1rem;
    }

    h1:first-child,
    h2:first-child,
    h3:first-child,
    h4:first-child,
    h5:first-child {
      margin-top: 0em;
    }

    h1:last-child,
    h2:last-child,
    h3:last-child,
    h4:last-child,
    h5:last-child {
      margin-bottom: 0em;
    }

    // Text

    p {
      margin: 0em 0em 1em;
      line-height: 1.4285em;
    }

    p:first-child {
      margin-top: 0em;
    }

    p:last-child {
      margin-bottom: 0em;
    }

    // Links

    a {
      text-decoration: none;
    }

    a:hover {
      text-decoration: none;
    }

    // Code

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }
    
    // Pseudo elements
    /* Scrollbars */

    body ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 10px;
      height: 10px;
    }

    body ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 0px;
    }

    body ::-webkit-scrollbar-thumb {
      cursor: pointer;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.25);
      -webkit-transition: color 0.2s ease;
      transition: color 0.2s ease;
    }

    body ::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(0, 0, 0, 0.15);
    }

    // TODO: move fake-table into components
    .ui.fake-table {
      width: 100%;
      background: ${standardLightStyles.surfaceBackground};
      margin: 1em 0;
      border: ${standardLightStyles.border};
      box-shadow: none;
      border-radius: 0.28571429rem;
      text-align: left;
      color: ${standardLightStyles.surfaceColor};
    }
    .ui.fake-table:first-child {
      margin-top: 0;
    }
    .ui.fake-table:last-child {
      margin-bottom: 0;
    }

    .ui.fake-table .th,
    .ui.fake-table .td {
      padding: 0.92857143em 2rem;
      transition: background ${standardLightStyles.fastTransition},
        color ${standardLightStyles.fastTransition};
    }

    .ui.fake-table .td {
      color: ${standardLightStyles.neutral600};
    }

    /* Headers */
    .ui.fake-table .thead {
      box-shadow: none;
    }
    .ui.fake-table .thead .tr,
    .thead.tr {
      display: flex;
      background-color: ${standardLightStyles.surfaceBackground};
    }
    .ui.fake-table .thead .th {
      background-color: ${standardLightStyles.surfaceBackground};
      cursor: auto;
      font-family: ${baseStyles.headerFontFamily};
      text-align: inherit;
      padding: 0.92857143em 2rem;
      font-style: none;
      font-weight: bold;
      text-transform: none;
      color: ${standardLightStyles.blue900};
      text-transform: uppercase;
      border-bottom: ${standardLightStyles.border};
      border-left: none;
      display: flex;
      white-space: nowrap;
      align-items: center;
      box-sizing: border-box;
      font-size: 12px;
    }

    .ui.fake-table .tfoot {
      box-shadow: none;
    }
    .ui.fake-table .tfoot.tr,
    .ui.fake-table .tfoot .tr {
      .th {
        background-color: ${standardLightStyles.surfaceBackground};
      }
    }
    .ui.fake-table .tfoot .th {
      cursor: auto;
      border-top: ${standardLightStyles.border};
      background: ${standardLightStyles.neutral0};
      text-align: inherit;
      font-family: ${baseStyles.headerFontFamily};
      color: ${standardLightStyles.blue900};
      padding-top: 14px;
      padding-bottom: 14px;
      font-size: 12px;
      vertical-align: middle;
      font-style: normal;
      font-weight: bold;
      text-transform: uppercase;
      display: flex;
      white-space: nowrap;
      align-items: center;
      box-sizing: border-box;
    }

    .ui.fake-table .tr {
      display: flex;
      white-space: nowrap;
      align-items: center;
      box-sizing: border-box;
      background: ${standardLightStyles.neutral0};
    }
  }
`;
